<ng-template #tooltipTemplate let-item="item">
  <div
    [ngClass]="{
      'button-tooltip': !item.disabledReasons,
      'disabled-reasons': item.disabledReasons,
    }">
    <span
      *ngIf="
        !item.isEnabled &&
        (!item.disabledReasons || item.disabledReasons.length === 0)
      ">
      {{ item.name }} is disabled
    </span>
    <div *ngFor="let reason of item.disabledReasons">{{ reason }}</div>
    <div *ngIf="item.tooltip">{{ item.tooltip }}</div>
  </div>
</ng-template>

<div class="button-panel d-flex flex-wrap">
  <div *ngFor="let item of buttons">
    <div
      *ngIf="item.isVisible && isButton(item)"
      class="d-inline-block"
      [ngbTooltip]="item.isEnabled ? null : tooltipTemplate"
      [tooltipContext]="{ item: item }"
      placement="bottom">
      <button
        class="btn m-1"
        [class]="item.style?.length > 0 ? item.style : buttonStyle"
        [id]="item.id"
        (click)="onButtonClicked(item)"
        [disabled]="!item.isEnabled">
        {{ item.name }}
      </button>
    </div>
    <div
      ngbDropdown
      class="d-inline-block"
      *ngIf="item.isVisible && isDropDown(item)">
      <button
        class="btn m-1"
        [class]="buttonStyle"
        [id]="item.id"
        ngbDropdownToggle>
        {{ item.name }}
      </button>
      <div ngbDropdownMenu>
        <div
          *ngFor="let dropDownItem of item.options"
          [ngbTooltip]="!dropDownItem.isEnabled ? tooltipTemplate : null"
          [tooltipContext]="{ item: dropDownItem }"
          placement="right"
          class="dropdown-item-wrapper">
          <button
            ngbDropdownItem
            [disabled]="!dropDownItem.isEnabled"
            (click)="onButtonClicked(dropDownItem)">
            {{ dropDownItem.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
