<div class="form-group">
  <div ngbDropdown #calendarPanel="ngbDropdown">
    <label *ngIf="!hideLabel" [for]="ngControl?.name" class="input-label"
      >{{ labelName }} <span *ngIf="flagAsRequired">*</span>
    </label>
    <div class="input-group">
      <input
        type="text"
        class="form-control"
        [name]="ngControl?.name"
        [(ngModel)]="displayValue"
        [placeholder]="mask"
        [disabled]="disabled"
        #glPeriodInput
        (input)="handleUserInput(glPeriodInput.value)" />
      <div class="input-group-append" ngbDropdownToggle>
        <span class="btn-icon">
          <app-icon class="icon" [name]="iconButton"></app-icon>
        </span>
      </div>
    </div>
    <div ngbDropdownMenu class="dropdown-menu container-fluid">
      <div class="row">
        <div class="col-4 d-flex justify-content-center">
          <button
            class="btn"
            [disabled]="!isValidYear(-1)"
            (click)="addYear($event, -1)">
            <span class="btn-icon">
              <app-icon class="icon" [name]="'caret-left'"></app-icon>
            </span>
          </button>
        </div>

        <div class="col-4 d-flex justify-content-center">
          <button
            *ngIf="!isYearModeActive"
            class="btn"
            (click)="showYear($event, true)">
            {{ glPeriod.year }}
          </button>
          <button
            *ngIf="isYearModeActive"
            class="btn"
            (click)="showYear($event, false)">
            {{ buttonIndex + 1 }}-{{ buttonIndex + 10 }}
          </button>
        </div>

        <div class="col-4 d-flex justify-content-center">
          <button class="btn" (click)="addYear($event, +1)">
            <span class="btn-icon">
              <app-icon class="icon" [name]="'caret-right'"></app-icon>
            </span>
          </button>
        </div>
      </div>
      <div class="row">
        <button
          *ngFor="
            let month of months;
            let i = index;
            let first = first;
            let last = last
          "
          class="col-4 selector btn d-flex justify-content-center"
          [disabled]="!getIsSelectionValid(i)"
          (click)="selectYearMonth($event, i)">
          <span *ngIf="!isYearModeActive">{{ month }}</span>
          <span *ngIf="isYearModeActive">{{ i + buttonIndex }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
