import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, last, take, takeUntil, takeWhile } from 'rxjs/operators';
import { SpreadsheetDownloadService } from '@app/shared/services/spreadsheet-download.service';
import { ExcelTableDefinition } from '@app/shared/models/excel-table-definition';
import { AuthenticationService } from '@app/authentication/services/authentication.service';
import { PermissionsEnum } from '@app/shared/models/permissions.enum';
import { Router } from '@angular/router';

import { errorless } from '@app/core/helpers/errorless';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportExcelModalComponent } from '@app/shared/dashboard-table/export-excel-modal/export-excel-modal.component';
import { AppConfigService } from '@app/core/services/app-config/app-config.service';
import { PagedApiData } from '@app/core/models/PagedApiData';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ExcelTableColumn } from '@app/shared/models/excel-table-column';
import { DateRangeFields } from '../models/date-range-field';
import { AutoUnsubscription } from '../helpers/observable-helpers';
import { defaultLimit, defaultOffset } from '../helpers/pagination-helpers';
import { TableDefinition } from './table-definitions/table-definition';

const visible = ({ isVisible }: { isVisible: any }): boolean => isVisible;

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss'],
})
export class DashboardTableComponent
  extends AutoUnsubscription
  implements OnInit, OnChanges, OnDestroy
{
  excelConversionEnabled: boolean;
  domain: string;
  private exportSubscriptionList = new Array<Subscription>();
  private rowsSubscription: Subscription;
  private selectFilterSubscription: Subscription;
  private currentRowData: PagedApiData<any>;

  @Input()
  rowHeight = 40;

  @Input()
  headerHeight = 40;

  @Input()
  stretchColumns = true;

  @Input()
  searchEnabled: boolean;

  @Input()
  filterEnabled: boolean;

  @Input()
  showPills: boolean;

  @Input()
  showActionButton = false;

  @Input()
  showAlternateActionButton = false;

  @Input()
  showSearch = false;

  @Input()
  tableDefinitions: TableDefinition<any>[] = [];

  @Input()
  nonClickableColumns: string[] = [];

  @Input()
  nonStyledColumns: string[] = [];
  @Input()
  shrinkHeight = true;

  dateRangeInputName = 'dateRange';

  get visibleActionButtons() {
    if (!this.selectedDefinition?.tableActions?.length) {
      return [];
    }

    return this.selectedDefinition.tableActions.filter(
      ({ isVisible }) => isVisible,
    );
  }

  selectedDefinition: TableDefinition<any>;

  simpleSearchInput: Subject<string>;
  alive = true;

  @Input()
  horizontalScrollbar = false;

  @Input()
  clearTableSelection = false;

  @Input()
  verticalScrollbar = true;

  @Input()
  showUpload = false;

  @Output()
  uploadClicked = new EventEmitter();

  @Output()
  actionButtonClicked = new EventEmitter();

  @Output()
  alternateActionButtonClicked = new EventEmitter();

  @Output()
  selectedRow = new EventEmitter();

  @Output()
  selectedTableDefinitionChanged = new EventEmitter<TableDefinition<any>>();

  @Input()
  useDefaultAll = false;

  parentForm: UntypedFormGroup;

  @Output()
  filterValueChanged = new EventEmitter();

  @Output()
  dateRangeValueChanged = new EventEmitter();

  constructor(
    private spreadSheetService: SpreadsheetDownloadService,
    private authService: AuthenticationService,
    private router: Router,
    private modalService: NgbModal,
    private appConfig: AppConfigService,
  ) {
    super();
    this.excelConversionEnabled =
      this.appConfig.config.features.tableExcelConversion.enabled &&
      this.authService.hasPermission(PermissionsEnum.TableConvert);
    this.domain = this.router.url.split('/')[1]
      ? this.router.url.split('/')[1]
      : 'bokamoso';
  }

  ngOnInit() {
    if (this.tableDefinitions && this.tableDefinitions.length > 0) {
      this.setSelectedTableDefinition();
      this.initializeSimpleSearchObservable();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tableDefinitions && changes.tableDefinitions.currentValue) {
      if (changes.tableDefinitions.currentValue.length > 0) {
        this.parentForm = new UntypedFormGroup({
          selectFilter: new UntypedFormControl(
            this.useDefaultAll ? 'All' : '',
            null,
          ),
        });
        this.setSelectedTableDefinition();
      }

      if (this.selectedDefinition.showSelectFilter) {
        this.subscribeToSelectFilter();
      }
    }
  }

  subscribeToDefinitionRows() {
    if (this.rowsSubscription) {
      this.rowsSubscription.unsubscribe();
    }
    this.rowsSubscription = this.selectedDefinition.rows$.subscribe(
      (rows) => (this.currentRowData = rows),
    );
  }

  ngOnDestroy() {
    this.alive = false;
    this.clearTableExportSubscriptions();
  }

  private setSelectedTableDefinition(
    definition?: TableDefinition<any>,
    dateRange?: DateRangeFields,
  ) {
    this.selectedDefinition = this.getSelectedTableDefinition(
      this.tableDefinitions,
      definition,
    );
    if (this.selectedDefinition.retrieveDateRangeValuesAcrossPills) {
      const validDateRange = this.getValidDateRangeValues(dateRange);
      this.selectedDefinition.dateRangeValues = validDateRange;
    }
    this.selectedTableDefinitionChanged.emit(this.selectedDefinition);

    if (this.selectedDefinition) {
      this.subscribeToDefinitionRows();

      this.selectedDefinition.initialize();
    }
  }

  private getValidDateRangeValues(dateRange?: DateRangeFields) {
    let validDateRange = null;

    if (
      this.selectedDefinition.retrieveDateRangeValuesAcrossPills &&
      dateRange?.fromDate?.length !== 0 &&
      dateRange?.toDate?.length !== 0
    ) {
      validDateRange = dateRange;
    }

    return validDateRange;
  }

  private getSelectedTableDefinition(
    definitions: TableDefinition<any> | TableDefinition<any>[],
    activeTableDefinition?: TableDefinition<any>,
  ) {
    if (definitions) {
      const isArray = definitions.hasOwnProperty('length');

      if (!activeTableDefinition) {
        if (isArray) {
          const visibleTableDefinitions = this.tableDefinitions.filter(visible);
          return visibleTableDefinitions[0];
        }
        return definitions as TableDefinition<any>;
      } else {
        if (
          isArray &&
          this.tableDefinitions.indexOf(activeTableDefinition) > -1
        ) {
          return activeTableDefinition;
        }
      }
      return null;
    }
  }

  initializeSimpleSearchObservable() {
    if (this.simpleSearchInput) {
      this.simpleSearchInput.unsubscribe();
    }

    this.simpleSearchInput = new Subject<string>();
    this.simpleSearchInput
      .pipe(
        takeWhile(() => this.alive),
        debounceTime(500),
      )
      .subscribe((searchString) => {
        this.selectedDefinition.simpleSearch(searchString);
      });
  }

  onPillClick(definition: TableDefinition<any>) {
    const selectedFilterValue = this.selectedDefinition.selectFilterValue;
    const dateRange = this.selectedDefinition.dateRangeValues ?? null;

    if (!definition.retrieveDateRangeValuesAcrossPills) {
      definition.dateRangeValues = null;
    }
    if (
      definition.showDateRangeFilter &&
      !definition.retrieveDateRangeValuesAcrossPills
    ) {
      this.resetDateRange();
    }
    this.setSelectedTableDefinition(definition, dateRange);

    if (this.selectedDefinition.showSelectFilter) {
      this.subscribeToSelectFilter();
      this.selectedDefinition.selectFilterValue = selectedFilterValue;
    }
  }

  doResetSearch() {
    this.selectedDefinition.search = '';
    this.doSearch();
  }

  activated(event: any) {
    this.selectedDefinition.select(event);
    this.selectedRow.emit(event);
  }

  onSort(event: any) {
    this.selectedDefinition.sort(event);
  }

  doSearch(searchString: string = '') {
    this.simpleSearchInput.next(searchString);
  }

  showAdvancedSearch() {
    this.selectedDefinition.advancedSearch();
  }

  doLoadMore(event: { limit: number; offset: number }) {
    this.selectedDefinition.loadMore(event);
  }

  onUploadClicked() {
    this.uploadClicked.emit();
  }

  onDateFilterChanged(dateRange: DateRangeFields) {
    this.selectedDefinition.dateRangeValues = dateRange;
    if (dateRange.toDate && dateRange.fromDate) {
      this.selectedDefinition.loadInitialData();
      this.dateRangeValueChanged.emit(dateRange);
    }
  }

  resetDateRange() {
    this.selectedDefinition.onSelectDateRangeFilterValueChanged(null);
    this.dateRangeValueChanged.emit();
    const dateRangeControl = this.parentForm.get(this.dateRangeInputName);
    if (dateRangeControl !== null) {
      dateRangeControl.reset();
    }
  }

  onActionButtonClicked() {
    this.actionButtonClicked.emit();
  }

  onAlternateActionButtonClicked() {
    this.alternateActionButtonClicked.emit();
  }

  async openExcelExportModal(maxRows: number): Promise<number> {
    const modal = this.modalService.open(ExportExcelModalComponent, {
      size: 'sm',
    });

    modal.componentInstance.apiData = this.selectedDefinition.rows$;
    modal.componentInstance.maxRows = maxRows;

    const result = await errorless(modal.result);

    return result;
  }

  removeDuplicateObjects(arr: any[]): any[] {
    const seen = new Set<any>();
    return arr.filter((obj) => {
      const objStr = JSON.stringify(obj);
      if (seen.has(objStr)) {
        return false;
      } else {
        seen.add(objStr);
        return true;
      }
    });
  }

  clearTableExportSubscriptions() {
    this.exportSubscriptionList.forEach((subscription, index) => {
      subscription.unsubscribe();
      this.exportSubscriptionList.splice(index);
    });
  }

  makeValueMultipleOfDefaultLimit(value: number): number {
    const remainder = value % defaultLimit;
    if (remainder === 0) {
      return value;
    }
    const difference = defaultLimit - remainder;
    return value + difference;
  }

  async convertTableToExcel() {
    if (!this.excelConversionEnabled) {
      return;
    }
    this.clearTableExportSubscriptions();
    this.subscribeToDefinitionRows();
    const maxRows = this.currentRowData.total;
    let rowsToLoad = await this.openExcelExportModal(maxRows);

    const modalSubmittedWithValue = !!rowsToLoad && rowsToLoad !== 0;
    if (!modalSubmittedWithValue) {
      return;
    }

    const currentRowsCount = this.currentRowData.data.length;
    const currentRows = this.currentRowData.data;
    const useDisplayedRows = rowsToLoad <= currentRowsCount;
    const allRowsDisplayed = this.currentRowData.total === currentRows.length;

    if (useDisplayedRows || allRowsDisplayed) {
      let uniqueObjects = this.removeDuplicateObjects(currentRows);

      uniqueObjects = uniqueObjects.splice(0, rowsToLoad);

      const excelTableDefinition = this.convertTableToExcelTableDefinition(
        this.selectedDefinition,
        uniqueObjects,
      );

      this.spreadSheetService.convertToExcel(excelTableDefinition);
    } else {
      const rowsEntered = rowsToLoad;

      rowsToLoad = this.makeValueMultipleOfDefaultLimit(rowsToLoad);

      this.doLoadMore({ limit: rowsToLoad, offset: defaultOffset });

      this.exportSubscriptionList.push(
        this.selectedDefinition.rows$
          .pipe(
            takeWhile(() => this.alive),
            take(2),
            last(),
          )
          .subscribe((apiData) => {
            if (apiData === null) {
              return;
            }

            let uniqueObjects = this.removeDuplicateObjects(apiData.data);
            uniqueObjects = uniqueObjects.splice(0, rowsEntered);
            const excelTableDefinition =
              this.convertTableToExcelTableDefinition(
                this.selectedDefinition,
                uniqueObjects,
              );

            this.spreadSheetService.convertToExcel(excelTableDefinition);
          }),
      );
    }
  }

  convertTableToExcelTableDefinition(
    tableDefinition: TableDefinition<any>,
    data: any[],
  ): ExcelTableDefinition {
    const tableDefinitionExcelColumns = tableDefinition.getExcelTableColumns();
    const headerNames = tableDefinitionExcelColumns.map(
      (column) => column.name,
    );
    const headers = tableDefinitionExcelColumns.map((column) =>
      column.prop.toString(),
    );
    const tableName = tableDefinition.title;
    const tableData = this.filterDataToColumnFields(
      data,
      tableDefinitionExcelColumns,
    );
    return {
      headerNames,
      headers,
      tableName,
      data: tableData,
      resourceName: tableName,
      domainName: this.domain,
    } as ExcelTableDefinition;
  }

  filterDataToColumnFields(data: any[], columns: ExcelTableColumn[]): any[] {
    return data.map((row) => {
      const rowCopy = { ...row };
      Object.keys(rowCopy).forEach((key) => {
        const filteredColumns = columns.filter((column) => column.prop === key);
        if (filteredColumns.length === 0) {
          return;
        }
        const pipe = filteredColumns[0].pipe;
        if (!pipe) {
          return;
        }
        rowCopy[key] = pipe.transform(rowCopy[key]);
      });

      return rowCopy;
    });
  }

  getSearchText() {
    if (this.selectedDefinition) {
      return 'Search';
    }
  }

  subscribeToSelectFilter() {
    if (this.selectFilterSubscription) {
      this.selectFilterSubscription.unsubscribe();
    }
    this.selectFilterSubscription =
      this.parentForm.controls.selectFilter.valueChanges
        .pipe(takeUntil(this.notifier))
        .subscribe((value) => this.onSelectFilterChanged(value));
  }

  onSelectFilterChanged(selectedFilterValue: string) {
    if (selectedFilterValue) {
      this.selectedDefinition.selectFilterValueChanged(selectedFilterValue);
      this.filterValueChanged.emit(selectedFilterValue);
    }
  }

  altAddDefaultSelectFilterValue() {
    if (
      this.selectedDefinition &&
      'altAddDefaultSelectFilterOption' in this.selectedDefinition
    ) {
      return this.selectedDefinition.altAddDefaultSelectFilterOption;
    }
    return false;
  }

  checkBoxSelected(selectedRows: any[]) {
    this.selectedDefinition.setCheckboxSelectedRows(selectedRows);
  }
}
