export enum Regions {
  Johannesburg = 1,
  CapeTown = 2,
  Durban = 9,
  Pretoria = 10,
  HeadOffice = 99,
}

export const displayReadableRegions = (value: Regions) => {
  switch (value) {
    case Regions.Johannesburg:
      return 'Johannesburg';
    case Regions.CapeTown:
      return 'Cape Town';
    case Regions.Durban:
      return 'KwaZulu-Natal';
    case Regions.Pretoria:
    case Regions.HeadOffice:
      return 'Head Office';
  }
};
